// src/Auth/LoginWithNumber.jsx
import React, { useState } from 'react';
import { setUpRecaptcha } from '../firebase/firebase';

const LoginWithNumber = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [confirmationResult, setConfirmationResult] = useState(null);
    const [error, setError] = useState('');

    const handleSendCode = async () => {
        if (!phoneNumber) {
            setError('Please enter a valid phone number.');
            return;
        }
        try {
            setError('');
            const result = await setUpRecaptcha(phoneNumber);
            setConfirmationResult(result);
            console.log('OTP sent successfully.');
        } catch (error) {
            setError('Failed to send OTP. Please try again.');
            console.error('Error during sending code:', error);
        }
    };

    const handleVerifyCode = async () => {
        if (!verificationCode) {
            setError('Please enter the verification code.');
            return;
        }
        try {
            setError('');
            const result = await confirmationResult.confirm(verificationCode);
            console.log('User signed in successfully:', result.user);
            // Redirect to dashboard or handle user info here
        } catch (error) {
            setError('Failed to verify code. Please try again.');
            console.error('Error during sign-in:', error);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md p-8 space-y-8 bg-white shadow-lg rounded-lg">
                <h2 className="text-2xl font-bold text-center">Phone Number Login</h2>
                <div className="space-y-4">
                    <div>
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                            Phone Number
                        </label>
                        <input
                            type="tel"
                            id="phone"
                            placeholder="+1234567890"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>

                    {confirmationResult && (
                        <div>
                            <label htmlFor="code" className="block text-sm font-medium text-gray-700">
                                Verification Code
                            </label>
                            <input
                                type="text"
                                id="code"
                                placeholder="Enter your code"
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            />
                        </div>
                    )}

                    {error && <p className="text-red-500 text-sm">{error}</p>}

                    <div className="flex items-center justify-between">
                        {!confirmationResult ? (
                            <button
                                onClick={handleSendCode}
                                className="w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                                Send Verification Code
                            </button>
                        ) : (
                            <button
                                onClick={handleVerifyCode}
                                className="w-full py-2 px-4 bg-green-600 hover:bg-green-700 text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                            >
                                Verify and Sign In
                            </button>
                        )}
                    </div>
                </div>
                <div id="recaptcha-container"></div>
            </div>
        </div>
    );
};

export default LoginWithNumber;
