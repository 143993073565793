import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';
import banner1 from '../../../Assests/Images/HomeBanner.jpg';
import PremMandir1 from "../../../Assests/Images/PremMandir1.jpg";
import PremMandir2 from "../../../Assests/Images/PremMandir2.jpg";
import PremMandir3 from "../../../Assests/Images/PremMandir3.jpg";


export const HeroSection = () => {

  return (
    <Swiper
    navigation={true}
    modules={[Navigation, Autoplay]} // 3. Add Autoplay module to the modules array
    autoplay={{ delay: 2500, disableOnInteraction: false }} // 4. Configure autoplay with delay and interaction settings
    className="mySwiper"
  > 
              <SwiperSlide>
        <div className="relative">
          <img src={banner1} alt="Banke Bihari Temple" className="w-full h-full object-cover" />
          <div className="absolute inset-0 bg-black bg-opacity-40"></div> {/* Dark overlay */}
          <div className="absolute inset-0 flex flex-col items-center justify-center text-center px-4">
            <h2 className="text-white text-3xl md:text-5xl font-bold mb-4 drop-shadow-lg">
              Banke Bihari Temple
            </h2>
            <p className="text-white text-lg md:text-2xl drop-shadow-md max-w-2xl">
              Experience the divine presence at one of Vrindavan's most revered temples.
            </p>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="relative">
          <img src={PremMandir1} alt="Prem Mandir" className="w-full h-full object-cover" />
          <div className="absolute inset-0 bg-black bg-opacity-40"></div>
          <div className="absolute inset-0 flex flex-col items-center justify-center text-center px-4">
            <h2 className="text-white text-3xl md:text-5xl font-bold mb-4 drop-shadow-lg">
              Prem Mandir
            </h2>
            <p className="text-white text-lg md:text-2xl drop-shadow-md max-w-2xl">
              A mesmerizing temple dedicated to divine love, illuminated beautifully at night.
            </p>
          </div>
        </div>
      </SwiperSlide>


        <SwiperSlide>
        <div className="relative">
          <img src={PremMandir2} alt="ISKCON Temple" className="w-full h-full object-cover" />
          <div className="absolute inset-0 bg-black bg-opacity-40"></div>
          <div className="absolute inset-0 flex flex-col items-center justify-center text-center px-4">
            <h2 className="text-white text-3xl md:text-5xl font-bold mb-4 drop-shadow-lg">
              ISKCON Temple
            </h2>
            <p className="text-white text-lg md:text-2xl drop-shadow-md max-w-2xl">
              Immerse yourself in spiritual chants and the serene atmosphere of ISKCON Vrindavan.
            </p>
          </div>
        </div>
      </SwiperSlide>

        <SwiperSlide>
          <a href="https://example.com/link4" target="_blank" rel="noopener noreferrer">
            <img src={PremMandir3} alt="Slide 4" className="w-full h-full object-cover" />
          </a>
        </SwiperSlide>
        {/* <SwiperSlide>
          <a href="https://example.com/link5" target="_blank" rel="noopener noreferrer">
            <img src="path/to/your/image5.jpg" alt="Slide 5" className="w-full h-full object-cover" />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://example.com/link6" target="_blank" rel="noopener noreferrer">
            <img src="path/to/your/image6.jpg" alt="Slide 6" className="w-full h-full object-cover" />
          </a>
        </SwiperSlide> */}
        {/* <SwiperSlide>
          <a href="https://example.com/link7" target="_blank" rel="noopener noreferrer">
            <img src="path/to/your/image7.jpg" alt="Slide 7" className="w-full h-full object-cover" />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://example.com/link8" target="_blank" rel="noopener noreferrer">
            <img src="path/to/your/image8.jpg" alt="Slide 8" className="w-full h-full object-cover" />
          </a>
        </SwiperSlide> */}
        {/* <SwiperSlide>
          <a href="https://example.com/link9" target="_blank" rel="noopener noreferrer">
            <img src="path/to/your/image9.jpg" alt="Slide 9" className="w-full h-full object-cover" />
          </a>
        </SwiperSlide> */}
      </Swiper>
  );
}

export default HeroSection;
