import React from 'react'
import HeroSection from './components/Header/Hero/HeroSection'
import ProductCardList from './components/Header/ProductCard/ProductCardList'
import Testimonials from './components/Header/Testimonial/Testimonials'
import ContactForm from './components/Header/ContactPage/ContactForm'

const Home = () => {
    return (
        <>
            <HeroSection />
            <ProductCardList />
            <Testimonials />
            <div className="px-8 sm:px-8 lg:px-8">
                <ContactForm />
            </div>
        </>
    )
}

export default Home