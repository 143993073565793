import React from 'react';

const AboutPage = () => {
  return (
    <div className="px-4 py-8">
      <h1 className="text-3xl font-bold mb-4 text-center">About Us</h1>
      <p className="mb-4">
        Welcome to our tour and travel company! We specialize in providing unique travel experiences in Mathura, Vrindavan, Barsana, and Gokul. Whether you're interested in exploring the spiritual sites, enjoying local festivals, or experiencing the cultural richness of these regions, we have something for everyone.
      </p>
      <p className="mb-4">
        Our packages include various modes of travel to suit your preferences. Choose from bike tours for the adventurous spirit, auto-rickshaws for a convenient ride, cars for comfort and flexibility, or scooters for a fun and agile way to explore.
      </p>
      <p>
        Contact us today to book your next memorable journey through these sacred and historic destinations!
      </p>
    </div>
  );
};

export default AboutPage;
