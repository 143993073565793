import React from 'react'

const TestimonialCard = ({ image, name, feedback, location }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition duration-300">
    <img src={image} alt={name} className="w-24 h-30 rounded-full mx-auto mb-4" />
    <h3 className="text-xl font-semibold text-gray-700 mb-2">{name}</h3>
    <p className="text-gray-600 italic mb-2">"{feedback}"</p>
    <p className="text-gray-500">{location}</p>
  </div>
  )
}

export default TestimonialCard;
