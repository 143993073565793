import React, { useState } from 'react'

const TravelBuddySection = () => {
  const [formData, setFormData] = useState({
    transportation: 'bike',
    days: '',
    pickupTime: '',
    dropoffTime: '',
    pickupLocation: '',
    dropoffLocation: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to a server
    console.log('Travel Buddy Booking Details:', formData);
  };
  
  return (
    <div className="max-w-lg mx-auto p-6 border border-gray-300 rounded-lg bg-white shadow-md">
    <h2 className="text-2xl font-semibold text-center mb-6">Book Your Travel Buddy</h2>
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="form-group">
        <label htmlFor="vehicle" className="block text-sm font-medium text-gray-700 mb-1">Choose Your Vehicle:</label>
        <select
          id="vehicle"
          name="vehicle"
          value={formData.vehicle}
          onChange={handleChange}
          className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option value="bike">Bike</option>
          <option value="car">Car</option>
          <option value="scooter">Scooter</option>
          <option value="van">Van</option>
        </select>
      </div>
      
      <div className="form-group">
        <label htmlFor="bookingDate" className="block text-sm font-medium text-gray-700 mb-1">Booking Date:</label>
        <input
          type="date"
          id="bookingDate"
          name="bookingDate"
          value={formData.bookingDate}
          onChange={handleChange}
          className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>

      <div className="form-group">
        <label htmlFor="days" className="block text-sm font-medium text-gray-700 mb-1">Number of Days:</label>
        <input
          type="number"
          id="days"
          name="days"
          min="1"
          value={formData.days}
          onChange={handleChange}
          className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>

      <div className="form-group">
        <label htmlFor="pickupTime" className="block text-sm font-medium text-gray-700 mb-1">Pickup Time:</label>
        <input
          type="time"
          id="pickupTime"
          name="pickupTime"
          value={formData.pickupTime}
          onChange={handleChange}
          className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>

      <div className="form-group">
        <label htmlFor="dropoffTime" className="block text-sm font-medium text-gray-700 mb-1">Dropoff Time:</label>
        <input
          type="time"
          id="dropoffTime"
          name="dropoffTime"
          value={formData.dropoffTime}
          onChange={handleChange}
          className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>

      <div className="form-group">
        <label htmlFor="pickupLocation" className="block text-sm font-medium text-gray-700 mb-1">Pickup Location:</label>
        <input
          type="text"
          id="pickupLocation"
          name="pickupLocation"
          value={formData.pickupLocation}
          onChange={handleChange}
          className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>

      <div className="form-group">
        <label htmlFor="dropoffLocation" className="block text-sm font-medium text-gray-700 mb-1">Dropoff Location:</label>
        <input
          type="text"
          id="dropoffLocation"
          name="dropoffLocation"
          value={formData.dropoffLocation}
          onChange={handleChange}
          className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>

      <button
        type="submit"
        className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Submit Booking
      </button>
    </form>
  </div>
  )
}

export default TravelBuddySection;