import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import ThankYou from '../Common/ThankYou';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send(
      'service_wkvgzcp',
      'template_cribqva',
      formData,
      'A0GbYQXD3T9aXl0L0'
    )
    .then(
      () => {
        console.log('SUCCESS!');
        setFormData({ name: "", email: "", phone: "", message: "" });
        setIsSubmitted(true); 
      },
      (error) => {
        console.log('FAILED...', error.text);
        alert('Failed to send the message. Please try again.');
      }
    );
  };

  const closeThankYou = () => {
    setIsSubmitted(false);
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-green-500 to-blue-700 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="container mx-auto">
        <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-center text-white mb-8 sm:mb-12">Contact Us</h1>
        <div className="max-w-lg mx-auto bg-white shadow-lg rounded-lg p-6 sm:p-8">
          <form onSubmit={handleSubmit}>
            <div className="mb-4 sm:mb-6">
              <label htmlFor="name" className="block text-gray-700 font-semibold">
                Name: <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="form-input mt-1 w-full px-3 py-2 sm:px-4 sm:py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Your Name"
                required
              />
            </div>
            <div className="mb-4 sm:mb-6">
              <label htmlFor="email" className="block text-gray-700 font-semibold">
                Email: <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="form-input mt-1 w-full px-3 py-2 sm:px-4 sm:py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Your Email"
                required
              />
            </div>
            <div className="mb-4 sm:mb-6">
              <label htmlFor="phone" className="block text-gray-700 font-semibold">
                Phone: <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="form-input mt-1 w-full px-3 py-2 sm:px-4 sm:py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Your Phone Number"
                required
              />
            </div>
            <div className="mb-4 sm:mb-6">
              <label htmlFor="message" className="block text-gray-700 font-semibold">
                Message:
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="4" 
                className="form-textarea mt-1 w-full px-3 py-2 sm:px-4 sm:py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Your Message"
              />
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="py-2 px-4 sm:py-3 sm:px-6 bg-yellow-300 text-blue-900 font-bold rounded-full shadow-md hover:bg-yellow-400 transition duration-300"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
      {isSubmitted && <ThankYou onClose={closeThankYou} />}
    </div>
  );
};

export default ContactForm;
