import React from 'react';
import { AiFillStar } from 'react-icons/ai'; // Import the solid star icon
import { FiMapPin } from 'react-icons/fi';

const CarBookingCard = ({ image, vehicleName, price, location, rating, description }) => {
  return (
    <div className="max-w-sm mx-auto bg-white shadow-lg rounded-xl overflow-hidden border border-gray-200 transition-all duration-300 hover:shadow-2xl hover:scale-105 mb-8">
      {/* Image Section */}
      <div className="relative">
        <img
          className="w-full h-48 object-cover"
          src={image}
          alt={`${vehicleName} Image`}
          loading="lazy"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent opacity-50"></div>
        <div className="absolute bottom-2 left-2 bg-blue-700 text-white text-xs px-2 py-1 rounded">
          Featured
        </div>
      </div>
      {/* Content Section */}
      <div className="p-4 space-y-2">
        <h2 className="text-2xl font-semibold text-gray-800">{vehicleName}</h2>
        <div className="flex items-center text-sm text-gray-500">
          <FiMapPin className="mr-1" />
          <span>{location}</span>
        </div>
        <div className="flex items-center text-sm text-yellow-500">
          <AiFillStar className="mr-1" />
          <span>{rating} / 5.0</span>
        </div>
        <p className="text-sm text-gray-600 line-clamp-2">{description}</p>
        <p className="text-lg font-bold text-gray-900">Price: ₹{price}</p>
        <div className="flex justify-between space-x-2 mt-4">
          <button className="flex-1 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 transition duration-200">
            Instant Booking
          </button>
          <button className="flex-1 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition duration-200">
            Book for Later
          </button>
        </div>
      </div>
    </div>
  );
};

export default CarBookingCard;
