import "./App.css";
import Header from "./components/Header/HeaderSection";
import FooterSection from "./components/Header/Footer/FooterSection";
import ContactForm from "./components/Header/ContactPage/ContactForm";
import {
  createBrowserRouter,
  Outlet,
  Router,
  RouterProvider,
} from "react-router-dom";
import Home from "./Home";
import Itineary from "./components/Header/Itineary/Itineary";
import TravelBuddySection from "./components/Header/TravelBuddy/TravelBuddySection";
import Login from "./Auth/Login";
import Signup from "./Auth/Signup";
import UserProfile from "./components/Header/Profile/UserProfile";
import LoginWithNumber from "./Auth/LoginWithNumber";
import AboutPage from "./components/Header/Aboutpage/AboutPage";
import CarBookingList from "./components/Header/CarParking/CarBookingList";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainComponent />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "contact-form",
        element: <ContactForm />,
      },
      {
        path: "about-page",
        element: <AboutPage />,
      },
      {
        path: "itineary",
        element: <Itineary />,
      },
      {
        path: "travel-buddy",
        element: <TravelBuddySection />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "loginWithOtp",
        element: <LoginWithNumber />,
      },
      {
        path: "profile",
        element: <UserProfile />,
      },
      {
        path: "carbookinglist",
        element: <CarBookingList />,
      },
    ],
  },
]);

function MainComponent() {
  return (
    <div className="font-sans antialiased text-gray-900">
      <Header />
      <div>
        <Outlet />
      </div>
      <FooterSection />
      <a
        href="https://wa.me/6284041058"
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-4 right-4 bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600 transition duration-300 z-50"
      >
        <i className="fa fa-whatsapp text-2xl"></i>
      </a>
    </div>
  );
}

function App() {
  return <RouterProvider router={router} />;
}

export default App;
