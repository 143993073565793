import React from 'react';

const ThankYou = ({ onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75">
      <div className="bg-white rounded-lg shadow-2xl p-8 text-center transform transition-all duration-500 ease-in-out scale-105">
        <div className="mb-4">
          <svg
            className="w-16 h-16 mx-auto text-green-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2 4-4m-6 4a9 9 0 110-18 9 9 0 010 18z"
            ></path>
          </svg>
        </div>
        <h1 className="text-4xl font-extrabold text-gray-900 mb-4">Thank You!</h1>
        <p className="text-lg text-gray-700 mb-2">Your form has been submitted successfully.</p>
        <p className="text-lg text-gray-700 mb-6">We will contact you shortly.</p>
        <button
          onClick={onClose}
          className="bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 transition duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default ThankYou;
