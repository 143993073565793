import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../Context/authContexts';
import { doSignInWithGoogle, doSignInWithGoogleRedirect, handleRedirectResult } from '../firebase/auth';

const Login = () => {
  const { userLoggedIn } = useAuth();
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const onGoogleSignIn = async (e) => {
    e.preventDefault();
    if (!isSigningIn) {
      setIsSigningIn(true);
      try {
        if (isMobileDevice()) {
          await doSignInWithGoogleRedirect();
        } else {
          await doSignInWithGoogle();
        }
      } catch (error) {
        if (error.code === 'auth/popup-closed-by-user') {
          setErrorMessage('The sign-in popup was closed before completing the sign-in process.');
        } else {
          setErrorMessage(error.message);
        }
      } finally {
        setIsSigningIn(false);
      }
    }
  };

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  useEffect(() => {
    handleRedirectResult().catch(error => setErrorMessage(error.message));
  }, []);

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      {userLoggedIn && <Navigate to='/profile' replace />}

      <main className="flex-grow flex items-center justify-center">
        <div className="w-full max-w-md bg-white p-8 shadow-lg rounded-lg space-y-6">
          <div className="text-center">
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">Welcome Back</h3>
            {errorMessage && (
              <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
            )}
          </div>

          <button
            disabled={isSigningIn}
            onClick={onGoogleSignIn}
            className={`w-full flex items-center justify-center gap-x-3 py-2 border rounded-lg text-sm font-medium ${isSigningIn ? 'bg-gray-200 cursor-not-allowed' : 'bg-white border-gray-300 hover:bg-gray-100'}`}
          >
            <svg className="w-5 h-5" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M47.532 24.5528C47.532 22.9214 47.3997 21.2811 47.1175 19.6761H24.48V28.9181H37.4434C36.9055 31.8988 35.177 34.5356 32.6461 36.2111V42.2078H40.3801C44.9217 38.0278 47.532 31.8547 47.532 24.5528Z" fill="#4285F4" />
              <path d="M24.48 48.0016C30.9529 48.0016 36.4116 45.8764 40.3888 42.2078L32.6549 36.2111C30.5031 37.675 27.7252 38.5039 24.4888 38.5039C18.2275 38.5039 12.9187 34.2798 11.0139 28.6006H3.03296V34.7825C7.10718 42.8868 15.4056 48.0016 24.48 48.0016Z" fill="#34A853" />
              <path d="M11.0051 28.6006C9.99973 25.6199 9.99973 22.3922 11.0051 19.4115V13.2296H3.03298C-0.371021 20.0112 -0.371021 28.0009 3.03298 34.7825L11.0051 28.6006Z" fill="#FBBC04" />
              <path d="M24.48 9.49932C27.9016 9.44641 31.2086 10.7339 33.6866 13.0973L40.5387 6.24523C36.2 2.17101 30.4414 -0.068932 24.48 0.00161733C15.4055 0.00161733 7.10718 5.11644 3.03296 13.2296L11.005 19.4115C12.901 13.7235 18.2187 9.49932 24.48 9.49932Z" fill="#EA4335" />
            </svg>
            {isSigningIn ? 'Signing In...' : 'Continue with Google'}
          </button>

          {/* <p className="text-center text-sm text-indigo-600 font-bold hover:underline cursor-pointer mt-4" onClick={() => navigate('/loginWithOtp')}>
            Login with OTP
          </p> */}

          {/* Uncomment this block if you want to display the sign-up link */}
          {/* <p className="text-center text-sm mt-4">
            Don't have an account? <Link to='/register' className="text-indigo-600 font-bold hover:underline">Sign up</Link>
          </p> */}
        </div>
      </main>
    </div>
  );
};

export default Login;
