import React from 'react';
import { FaInstagram, FaFacebook, FaLinkedin, FaWhatsapp } from 'react-icons/fa'; // Import Font Awesome icons including WhatsApp

const FooterSection = () => {
  return (
    <footer className="bg-black text-white py-8">
      <div className="container mx-auto px-6">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
          {/* Left Section: Company Name and Links */}
          <div className="mb-6 md:mb-0">
            <h2 className="text-green-500 text-2xl font-bold">Krishna Divine Tour</h2>
            <p className="text-sm mt-1">A Divine Tour</p>
            <ul className="mt-4">
              <li className="mb-2"><a href="#about" className="hover:underline">About us</a></li>
              <li className="mb-2"><a href="#contact" className="hover:underline">Contact us</a></li>
              <li><a href="#blogs" className="hover:underline">Travel Blogs</a></li>
            </ul>
          </div>

          {/* Right Section: Contact Information and Social Media Icons */}
          <div className="text-left md:text-right">
            <h3 className="font-semibold mb-4">Contact Us</h3>
            <div className="flex space-x-4 mb-4 justify-center md:justify-end">
              <a href="https://www.instagram.com/krishna_divine_tour/" target="_blank" className="hover:text-gray-400">
                <FaInstagram size={24} />
              </a>
              <a href="https://www.facebook.com/profile.php?id=61565015902344" target='_blank' className="hover:text-gray-400">
                <FaFacebook size={24} />
              </a>
              <a href="https://www.linkedin.com/in/krishna-divine-tour-625466305/" target="_blank" className="hover:text-gray-400">
                <FaLinkedin size={24} />
              </a>
              <a href="https://wa.me/6284041058" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400">
                <FaWhatsapp size={28} className="text-green-500" />
              </a>
            </div>
            <p><a href="tel:+916284041058" className="hover:underline">+91 6284041058</a></p>
            <p><a href="mailto:support@trawic.com" className="hover:underline">support@krishnadivinetour.com</a></p>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-8 border-t border-gray-700 pt-4 text-center text-sm">
          <p>&copy; 2024 Krishna Divine Tour | Company Registered as Krishna Divine Tour Pvt Ltd</p>
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
