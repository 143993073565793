import { auth } from './firebase';
import { 
    GoogleAuthProvider, 
    signInWithPopup, 
    updatePassword,
    signOut as firebaseSignOut, 
    signInWithRedirect,
    getRedirectResult,
    RecaptchaVerifier,
    signInWithPhoneNumber
} from 'firebase/auth';

// Sign in with Google
export const doSignInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
        const result = await signInWithPopup(auth, provider);
        return result;
    } catch (error) {
        console.error("Error signing in with Google:", error.message);
        throw error; // Handle at the component level if needed
    }
}

// Sign out the current user
export const doSignOut = async () => {
    try {
        await firebaseSignOut(auth);
    } catch (error) {
        console.error("Error signing out:", error.message);
        throw error; // Handle at the component level if needed
    }
}

// Reset the password for the current user
export const doPasswordReset = async (newPassword) => {
    if (!auth.currentUser) {
        throw new Error("No user is currently signed in.");
    }
    try {
        await updatePassword(auth.currentUser, newPassword);
    } catch (error) {
        console.error("Error updating password:", error.message);
        throw error; // Handle at the component level if needed
    }
}

// Google Sign-In (Redirect) 
export const doSignInWithGoogleRedirect = () => {
    const provider = new GoogleAuthProvider();
    return signInWithRedirect(auth, provider);
};

// Handle result after Google Sign-In redirect
export const handleRedirectResult = async () => {
    try {
        const result = await getRedirectResult(auth);
        if (result) {
            const user = result.user;
            console.log('User signed in via redirect:', user);
        }
    } catch (error) {
        console.error('Error handling redirect result:', error.message);
        throw error; // Handle at the component level if needed
    }
};

// Setup Recaptcha and handle phone number sign-in
export const setUpRecaptcha = (phoneNumber) => {
    return new Promise((resolve, reject) => {
        // Initialize the RecaptchaVerifier
        const recaptchaVerifier = new RecaptchaVerifier(
            'recaptcha-container', 
            {
                size: 'invisible', // Can be 'normal' for visible reCAPTCHA
                callback: (response) => {
                    console.log('reCAPTCHA solved:', response);
                },
                'expired-callback': () => {
                    console.error('reCAPTCHA expired. Please try again.');
                }
            }, 
            auth
        );

        // Render reCAPTCHA widget
        recaptchaVerifier.render()
            .then((widgetId) => {
                console.log('ReCAPTCHA widget rendered with ID:', widgetId);

                // Sign in with phone number using reCAPTCHA verifier
                return signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
            })
            .then((confirmationResult) => {
                resolve(confirmationResult); // Resolve on success
            })
            .catch((error) => {
                console.error('Error during phone authentication:', error.message);
                reject(error); // Reject on failure
            });
    });
};
