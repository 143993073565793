import React from 'react';
import ProductCard from './ProductCard';
import iskcon from '../../../Assests/Images/CardImages/iskcon.jpg';
import prem from '../../../Assests/Images/CardImages/prem.jpg';

const ProductCardList = () => {
    const products = [
        { id: 1, image: iskcon, title: 'Vrindavan-Mathura-Gokul', description: 'Explore the holy cities of Vrindavan and Mathura with visits to temples, ghats, and local markets. This is a perfect getaway to experience the divine culture and heritage of these sacred places.', days: '2D/1N', price: 4999 },
        { id: 2, image: prem, title: 'Vrindavan-Mathura-Gokul', description: 'Discover the spiritual essence of Vrindavan, Mathura, and Gokul. Enjoy guided tours of significant temples, historical sites, and serene landscapes. An enriching journey through the holy land.', days: '3D/2N', price: 6999 },
        { id: 3, image: iskcon, title: 'Vrindavan-Mathura-Barsana', description: 'Immerse yourself in the vibrant culture of Vrindavan, Mathura, and Barsana. Explore temples, festivals, and traditional events that bring these historical towns to life. An unforgettable spiritual adventure.', days: '7D/6N', price: 19999 }
    ];
    return (
        <div className="product-card-list">
            <h2 className="text-4xl font-bold text-gray-800 mt-6 text-center">Tour Packages</h2>
            <div className="flex flex-wrap justify-center mt-4 p-4">
                {products.map(product => (
                    <ProductCard
                        key={product.id}
                        id={product.id}
                        image={product.image}
                        title={product.title}
                        description={product.description}
                        days={product.days}
                        price={product.price}
                    />
                ))}
            </div>
        </div>
    )
}

export default ProductCardList;
