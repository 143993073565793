import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg overflow-hidden shadow-xl max-w-lg w-full max-h-[90vh]">
                <div className="relative">
                    <button
                        onClick={onClose}
                        className="absolute top-2 right-2 text-gray-700 font-bold text-2xl"
                    >
                        &times;
                    </button>
                    <div className="p-4 overflow-auto max-h-[70vh]">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;
