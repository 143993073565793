import React from 'react'

const Carfilter = ({filter,setFilter}) => {
    const getButtonStyle = (type) =>
        filter === type ? 'bg-blue-700 text-white' : 'bg-gray-200 text-gray-800';
    
      return (
        <div className="flex justify-center space-x-4 mb-4">
          <button
            onClick={() => setFilter('all')}
            className={`px-4 py-2 rounded hover:bg-gray-300 transition ${getButtonStyle(
              'all'
            )}`}
          >
            All
          </button>
          <button
            onClick={() => setFilter('car')}
            className={`px-4 py-2 rounded hover:bg-gray-300 transition ${getButtonStyle(
              'car'
            )}`}
          >
            Cars
          </button>
          <button
            onClick={() => setFilter('bike')}
            className={`px-4 py-2 rounded hover:bg-gray-300 transition ${getButtonStyle(
              'bike'
            )}`}
          >
            Bikes
          </button>
        </div>
      );
    };

export default Carfilter