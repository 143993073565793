import React from 'react'
import TestimonialCard from './TestimonialCard'

const Testimonials = () => {
  const testimonials = [
    {
      name: "Mukul",
      feedback: "An amazing experience! The tour was well-organized and the guide was very knowledgeable.",
      location: "Rajasthan",
      image: "https://i.imghippo.com/files/Ki8dM1725524489.jpg"
    },
    {
      name: "Saurabh",
      feedback: "I had a fantastic time! The sights were breathtaking and everything was taken care of.",
      location: "Delhi",
      image: "https://i.imghippo.com/files/SKHhS1725604100.jpg"
    },
    {
      name: "Krish",
      feedback: "Highly recommend Krishna Divine Tours! Exceptional service and unforgettable memories.",
      location: "Lucknow",
      image: "https://i.imghippo.com/files/7Mwia1725592828.jpg"
    }
  ];
  return (
    <section id="testimonials" className="py-12 bg-gray-100">
    <div className="container mx-auto text-center px-6">
      <h2 className="text-4xl font-bold text-gray-800 mb-8">What Our Customers Say</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {testimonials.map((testimonial, index) => (
          <TestimonialCard
            key={index}
            image={testimonial.image}
            name={testimonial.name}
            feedback={testimonial.feedback}
            location={testimonial.location}
          />
        ))}
      </div>
    </div>
  </section>
  )
}

export default Testimonials