import React, { useState } from "react";
import CarBookingCard from "./CarBookingCard";
import Carfilter from "./Carfilter";

const carData = [
  {
    image: "https://img.freepik.com/free-photo/curly-haired-hindu-hurries-meeting_496169-2750.jpg?w=740&t=st=1725520073~exp=1725520673~hmac=7f6f88016b613e88d73b5cdbfb40349657e911ebe9129a4be8e7a88698b41405",
    vehicleName: "Sedan",
    price: 500,
    location: "New Delhi, India",
    rating: 4.5,
    description: "Comfortable sedan with ample space for city rides.",
    type: "car",
  },
  {
    image: "https://img.freepik.com/free-photo/curly-haired-hindu-hurries-meeting_496169-2750.jpg?w=740&t=st=1725520073~exp=1725520673~hmac=7f6f88016b613e88d73b5cdbfb40349657e911ebe9129a4be8e7a88698b41405",
    vehicleName: "SUV",
    price: 700,
    location: "Mumbai, India",
    rating: 4.8,
    description: "Spacious SUV perfect for long drives and family trips.",
    type: "car",
  },
  {
    image: "https://img.freepik.com/free-photo/curly-haired-hindu-hurries-meeting_496169-2750.jpg?w=740&t=st=1725520073~exp=1725520673~hmac=7f6f88016b613e88d73b5cdbfb40349657e911ebe9129a4be8e7a88698b41405",
    vehicleName: "Hatchback",
    price: 400,
    location: "Bangalore, India",
    rating: 4.3,
    description: "Compact and fuel-efficient hatchback for city commutes.",
    type: "car",
  },
  {
    image: "https://via.placeholder.com/400",
    vehicleName: "Luxury Bike",
    price: 1500,
    location: "Hyderabad, India",
    rating: 4.9,
    description: "Luxury bike with premium features for a thrilling ride.",
    type: "bike",
  },
];
const CarBookingList = () => {
  const [filter, setFilter] = useState("all");

  // Filter data based on selected type
  const filteredData =
    filter === "all" ? carData : carData.filter((item) => item.type === filter);

  return (
    <div className="p-4">
      {/* Filter Component */}
      <Carfilter filter={filter} setFilter={setFilter} />

      {/* Display Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {filteredData.map((car, index) => (
          <CarBookingCard
            key={index}
            image={car.image}
            vehicleName={car.vehicleName}
            price={car.price}
            location={car.location}
            rating={car.rating}
            description={car.description}
          />
        ))}
      </div>
    </div>
  );
};

export default CarBookingList;
