// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, RecaptchaVerifier,PhoneAuthProvider, signInWithPhoneNumber } from 'firebase/auth';


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBe8VrdSSscyzEx72bojJyeuiRt_Zx7PFg",
  authDomain: "krishnadivinetour.firebaseapp.com",
  projectId: "krishnadivinetour",
  storageBucket: "krishnadivinetour.appspot.com",
  messagingSenderId: "567148141716",
  appId: "1:567148141716:web:dd6ca49c13c8cb99222881",
  measurementId: "G-E9TPCCYLW3"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export const setUpRecaptcha = (phoneNumber) => {
  const recaptchaVerifier = new RecaptchaVerifier(
    "recaptcha-container",
    {
      size: "invisible",
    },
    auth
  );

  return signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
};
export { app, auth };