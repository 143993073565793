import React, { useState } from 'react';
import { faUser, faPhone, faCalendar, faUsers, faComments, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ThankYou from '../Common/ThankYou';

const BookingPage = ({onClose}) => {
  
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    days: '', 
    people: '',
    other: '',
    arrivalDate: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Booking Details:', formData);
    setIsSubmitted(true);
    // onClose();
  };

  const closeThankYou = () => {
    setIsSubmitted(false);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative w-full max-w-lg sm:max-w-xl md:max-w-2xl bg-white rounded-lg shadow-lg p-8 md:p-10 overflow-y-auto h-auto max-h-[90vh]">
        <div className="absolute top-4 right-4 text-gray-500 cursor-pointer  transition-opacity duration-300 hover:opacity-100" onClick={closeThankYou}>
          <FontAwesomeIcon icon={faTimes} size="lg" onClose={closeThankYou} />
        </div>
        <h2 className="text-2xl md:text-3xl font-bold text-center text-gray-800 mb-6">Book Your Stay</h2>
        <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
          <div className="relative">
            <FontAwesomeIcon icon={faUser} className="absolute left-3 top-3 text-gray-400" />
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full pl-10 pr-4 py-2 md:py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
              placeholder="Your Name"
              required
            />
          </div>
          <div className="relative">
            <FontAwesomeIcon icon={faPhone} className="absolute left-3 top-3 text-gray-400" />
            <input
              type="text"
              id="number"
              name="number"
              value={formData.number}
              onChange={handleChange}
              className="w-full pl-10 pr-4 py-2 md:py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
              placeholder="Phone Number"
              required
            />
          </div>
          <div className="relative">
            <FontAwesomeIcon icon={faCalendar} className="absolute left-3 top-3 text-gray-400" />
            <input
              type="number"
              id="days"
              name="days"
              value={formData.days}
              onChange={handleChange}
              className="w-full pl-10 pr-4 py-2 md:py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
              placeholder="Number of Days"
              required
            />
          </div>
          <div className="relative">
            <FontAwesomeIcon icon={faUsers} className="absolute left-3 top-3 text-gray-400" />
            <input
              type="number"
              id="people"
              name="people"
              value={formData.people}
              onChange={handleChange}
              className="w-full pl-10 pr-4 py-2 md:py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
              placeholder="Number of People"
              required
            />
          </div>
          <div className="relative">
            <FontAwesomeIcon icon={faCalendar} className="absolute left-3 top-3 text-gray-400" />
            <input
              type="date"
              id="arrivalDate"
              name="arrivalDate"
              value={formData.arrivalDate}
              onChange={handleChange}
              className="w-full pl-10 pr-4 py-2 md:py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
              required
            />
          </div>
          <div className="relative">
            <FontAwesomeIcon icon={faComments} className="absolute left-3 top-3 text-gray-400" />
            <textarea
              id="other"
              name="other"
              value={formData.other}
              onChange={handleChange}
              className="w-full pl-10 pr-4 py-2 md:py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
              placeholder="Any special requests or comments?"
              rows="4"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full py-3 md:py-4 bg-blue-600 text-white font-bold rounded-lg hover:bg-blue-700 transition-colors duration-300"
          >
            Confirm Booking
          </button>
        </form>
        {isSubmitted && <ThankYou onClose={closeThankYou} />}
      </div>
    </div>
  );
};

export default BookingPage;
