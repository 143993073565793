import React from 'react';

const itineraryData = {
  title: "Mathura - Vrindavan - Gokul",
  overview: `Embark on a soul-stirring journey through the sacred lands of Mathura and Gokul with our carefully curated devotional tour package. 
    This itinerary offers a perfect blend of spiritual enlightenment and historical exploration, taking you to the most significant sites 
    associated with Lord Krishna's life. In Mathura, the birthplace of Lord Krishna, you'll visit iconic temples and ghats. The highlight is Shree Krishna Janmabhoomi, where devotees 
    can connect with the very essence of Krishna's divine energy. The itinerary also includes the architectural brilliance of Birla Mandir and an 
    optional boat ride near KansGali for a serene experience on the Yamuna River.`,
  highlights: [
    "Brij Darshan - Site 1 (1-Day Itinerary) starts from 7:00 am Morning to 7:00 pm. Car and Auto Rickshaw Tour.",
    "Please note: The itinerary and tour package is for information purposes, locations and temples can be closed or adjusted due to traffic / festivals / temple timings accordingly.",
    "Extra charges for Boating at Vishram Ghat.",
  ],
  itinerary: [
    {
      time: "Morning - Mathura",
      description: `Starts from 7:00 am Morning to 7:00 pm After full day and evening darshan yatra will back to Vrindavan for stay.`,
      places: [
        { name: "Shree Dwarkadish Temple", detail: "Begin your spiritual journey at the revered Dwarkadish Temple, dedicated to Lord Krishna in his Dwarka form.", image: "dwarkadish-temple.jpg" },
        { name: "VishramGhat", detail: "Visit the tranquil VishramGhat, where Lord Krishna is believed to have rested after defeating the demon Kansa.", image: "vishramghat.jpg" },
        { name: "Birla Mandir", detail: "Admire the architectural beauty of Birla Mandir, a modern temple dedicated to Lord Krishna.", image: "birla-mandir.jpg" },
        { name: "KansGali (Optional Boating)", detail: "Optionally, indulge in a serene boat ride on the Yamuna River, offering picturesque views of KansGali, the ancient fort associated with the legendary tales of Lord Krishna's life.", image: "kansgali.jpg" },
      ],
    },
    {
      time: "Evening - Gokul",
      description: `Yatra will start in the morning after darshan of Krishna Janm Bhoomi Mathura. The yatra will depart towards Gokul. After full day and evening darshan yatra will back to Vrindavan for stay.`,
      places: [
        { name: "Raman Reti", detail: "Experience the divine energy of Raman Reti where Lord Krishna spent his playful childhood days.", image: "raman-reti.jpg" },
        { name: "Gokul Mandir", detail: "Pay homage at Gokul Mandir, dedicated to Lord Krishna's childhood form, and feel the spiritual aura surrounding the temple.", image: "gokul-mandir.jpg" },
        { name: "84 Khamba", detail: "Visit the revered 84 Khamba, a historical site believed to be the place where Lord Krishna performed the Raaslila with the Gopis.", image: "84-khamba.jpg" },
        { name: "OhikaBandhan", detail: "Explore OhikaBandhan, associated with the enchanting tales of Lord Krishna's feats as a child.", image: "ohikabandhan.jpg" },
        { name: "Brahmand Ghat", detail: "Take a serene stroll along the Brahmand Ghat, a sacred spot on the banks of the Yamuna River, resonating with divine vibes.", image: "brahmand-ghat.jpg" },
        { name: "Chintaharan Mahadev", detail: "Conclude your journey with a visit to Chintaharan Mahadev, a significant temple dedicated to Lord Shiva, known for relieving devotees from worldly worries.", image: "chintaharan-mahadev.jpg" },
      ],
    },
  ],
  included: [
    "Accommodation with twin sharing bed room.",
    "Tour Guide with yatra.",
    "Cab for yatra pick up and drop from destination.",
    "Prasad and bhog prasad of Bankey Bihari temple.",
    "Darshan in various temples.",
    "Taxi and parking of cabs is included.",
  ],
  excluded: [
    "Excluding lunch.",
    "Basic convenience not included.",
    "Pooja in yatra.",
  ],
};

const ItineraryPage = () => {
  return (
    <div className="bg-white px-4 md:px-16 lg:px-24 py-8">
      {/* Overview Section */}
      <section className="mb-8">
        <h1 className="text-3xl font-bold text-gray-800 mb-4">{itineraryData.title}</h1>
        <p className="text-gray-700 text-lg leading-relaxed mb-4">{itineraryData.overview}</p>
      </section>

      {/* Highlights Section */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Highlights</h2>
        <ul className="list-disc list-inside text-gray-700 space-y-2">
          {itineraryData.highlights.map((highlight, index) => (
            <li key={index}>{highlight}</li>
          ))}
        </ul>
      </section>

      {/* Itinerary Section */}
      <section>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Itinerary</h2>
        {itineraryData.itinerary.map((day, index) => (
          <div key={index} className="border-t border-gray-300 pt-4 mt-6">
            <h3 className="text-xl font-medium text-gray-800 mb-2">{day.time}</h3>
            <p className="text-gray-700 mb-4">{day.description}</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {day.places.map((place, placeIndex) => (
                <div key={placeIndex} className="flex flex-col items-center bg-gray-100 p-4 rounded-lg shadow-lg">
                  <img src={`images/${place.image}`} alt={place.name} className="w-full h-48 object-cover mb-4 rounded-lg" />
                  <h4 className="text-lg font-semibold text-gray-800">{place.name}</h4>
                  <p className="text-gray-600 text-center mt-2">{place.detail}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </section>

      {/* Included/Excluded Section */}
      <section className="mt-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Included/Excluded</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <ul className="list-disc list-inside text-gray-700 space-y-2">
            {itineraryData.included.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <ul className="list-disc list-inside text-red-500 space-y-2">
            {itineraryData.excluded.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      </section>
    </div>
  );
};

export default ItineraryPage;
