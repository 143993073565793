import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../Context/authContexts';


const UserProfile = () => {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newProfileInfo, setNewProfileInfo] = useState({
    name: '',
    email: ''
  });

  useEffect(() => {
    if (currentUser) {
      setUserData({
        name: currentUser.displayName || 'User',
        email: currentUser.email || 'Not provided',
        profilePicture: currentUser.photoURL || 'default-profile-pic.jpg'
      });
    }
  }, [currentUser]);

  const handleUpdateProfile = () => {
    // Handle profile update logic here
    // For example, update the user profile information
    setShowModal(false);
  };
  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-6 max-w-4xl mx-auto bg-white shadow-lg rounded-lg border border-gray-200">
    <div className="flex items-center space-x-6 mb-6">
      <img
        className="w-32 h-32 rounded-full border-4 border-gray-200"
        src={userData.profilePicture}
        alt="Profile"
      />
      <div>
        <h1 className="text-3xl font-semibold text-gray-800">{userData.name}</h1>
        <p className="text-gray-600 text-lg">{userData.email}</p>
      </div>
    </div>
    
    <div className="bg-gray-50 p-4 rounded-lg shadow-inner mb-6">
      <h2 className="text-2xl font-semibold text-gray-700 mb-4">My Bookings</h2>
      <ul className="list-disc pl-5 text-gray-700">
        <li>Tour to Paris - July 2024</li>
        <li>Safari in Kenya - October 2024</li>
        <li>Beach Vacation in Bali - December 2024</li>
      </ul>
    </div>

    <div className="flex justify-end">
      <button 
        className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
        onClick={() => setShowModal(true)}
      >
        Update Profile
      </button>
    </div>

    {/* Update Profile Modal */}
    {showModal && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
          <h3 className="text-2xl font-semibold mb-4">Update Profile</h3>
          <input
            type="text"
            placeholder="Name"
            value={newProfileInfo.name}
            onChange={(e) => setNewProfileInfo({ ...newProfileInfo, name: e.target.value })}
            className="border border-gray-300 rounded-lg p-2 w-full mb-4"
          />
          <input
            type="email"
            placeholder="Email"
            value={newProfileInfo.email}
            onChange={(e) => setNewProfileInfo({ ...newProfileInfo, email: e.target.value })}
            className="border border-gray-300 rounded-lg p-2 w-full mb-4"
          />
          <div className="flex justify-end space-x-4">
            <button 
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
            <button 
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
              onClick={handleUpdateProfile}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    )}
  </div>
  );
};

export default UserProfile;
